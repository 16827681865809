import React from 'react'
import Intoduction from './components/Intoduction'
import About from './components/About'

const App = () => {
  return (
    <div>
<Intoduction/>

    </div>
  )
}

export default App